import React, { FC, useState } from 'react'
import { TDatePickerDate } from '../../../../interfaces/helpers'
import { IOrganization } from '../../../../interfaces/passport/organizations'
import Pagetitle from '../../../../components/UI/Pagetitle/Pagetitle'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { selectProps } from '../../../../components/UI/Select/helpers'
import InputDatePicker from '../../../../components/UI/InputDatePicker/InputDatePicker'
import { getDate, REGEX_EMAIL } from '../../../../helpers/helpers'
import { useTranslation } from 'react-i18next'
import { AxiosPromise } from 'axios'
import Input from '../../../../components/UI/Input/Input'

export interface IFormOrganizationUsersInputs {
	organizationIds: string
	reportCreatedFrom: TDatePickerDate
	reportCreatedTo: TDatePickerDate
	sendTo?: string
}

interface IProps {
	onSubmit: (data: IFormOrganizationUsersInputs) => AxiosPromise<BlobPart>
	organizations: IOrganization[]
}

const ReportByOrganizationUsers: FC<IProps> = ({ onSubmit, organizations }) => {
	const { t } = useTranslation()

	const {
		errors,
		handleSubmit,
		control,
		watch,
		register,
		formState: { isSubmitting }
	} = useForm<IFormOrganizationUsersInputs>({
		defaultValues: {
			sendTo: '',
			organizationIds: '',
			reportCreatedFrom: null,
			reportCreatedTo: null
		}
	})

	const [reportCreatedFrom, setReportCreatedFrom] = useState<TDatePickerDate>(null)
	const [minReportCreatedFrom] = useState<TDatePickerDate>(null)
	const [maxReportCreatedFrom, setMaxReportCreatedFrom] = useState<TDatePickerDate>(null)
	const [reportCreatedTo, setReportCreatedTo] = useState<TDatePickerDate>(null)
	const [minReportCreatedTo, setMinReportCreatedTo] = useState<TDatePickerDate>(null)
	const [maxReportCreatedTo] = useState<TDatePickerDate>(null)

	const reportCreatedFromHandler = (date: TDatePickerDate) => {
		setReportCreatedFrom(date)
		setMinReportCreatedTo(date)
	}
	const reportCreatedToHandler = (date: TDatePickerDate) => {
		setReportCreatedTo(date)
		setMaxReportCreatedFrom(date)
	}

	return (
		<>
			<Pagetitle title={t('pages:reports:title_users')}>
				<p>{t('pages:reports:text')}</p>
			</Pagetitle>

			<form className="form form--report" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-row">
					<div className="form-col">
						<div className="form-item">
							<Controller
								render={({ onChange, name }) => (
									<Select
										name={name}
										options={organizations.map(item => ({
											label: item.name,
											value: item.id
										}))}
										defaultValue={undefined}
										placeholder={t('pages:reports:fieldOrganizationIds')}
										isDisabled={isSubmitting}
										isError={!!errors.organizationIds}
										errors={errors.organizationIds}
										closeMenuOnSelect={false}
										onChange={option => {
											if (option) {
												onChange(option.map(item => item.value).join(','))
											} else {
												onChange('')
											}
										}}
										isMulti={true}
										{...selectProps}
									/>
								)}
								name={'organizationIds'}
								control={control}
							/>
						</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-col">
						<div className="form-headnote">{t('pages:reports:reportCreatedRange')}</div>

						<div className="form-item form-item--calendar">
							<Controller
								render={({ name, onChange }) => (
									<InputDatePicker
										name={name}
										placeholderText={t('pages:reports:fieldReportCreatedFrom')}
										selected={reportCreatedFrom}
										minDate={minReportCreatedFrom}
										maxDate={maxReportCreatedFrom}
										onChange={(date: TDatePickerDate) => {
											reportCreatedFromHandler(date)
											onChange(getDate(date))
										}}
										errors={errors.reportCreatedFrom}
										isError={!!errors.reportCreatedFrom}
										isDisabled={isSubmitting}
									/>
								)}
								control={control}
								name={'reportCreatedFrom'}
								defaultValue={reportCreatedFrom}
							/>

							<span className="form-delimiter">—</span>

							<Controller
								render={({ name, onChange }) => (
									<InputDatePicker
										name={name}
										placeholderText={t('pages:reports:fieldReportCreatedTo')}
										selected={reportCreatedTo}
										minDate={minReportCreatedTo}
										maxDate={maxReportCreatedTo}
										onChange={(date: TDatePickerDate) => {
											reportCreatedToHandler(date)
											onChange(getDate(date))
										}}
										errors={errors.reportCreatedTo}
										isError={!!errors.reportCreatedFrom}
										isDisabled={isSubmitting}
									/>
								)}
								control={control}
								name={'reportCreatedTo'}
								defaultValue={reportCreatedTo}
							/>
						</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-col">
						<div className="form-headnote">{t('pages:reports:reportSendTo')}</div>

						<div className="form-item">
							<Input
								currentValue={watch('sendTo')}
								type={'email'}
								name={'sendTo'}
								placeholder={t('pages:reports:fieldEmail')}
								isDisabled={isSubmitting}
								isError={!!errors.sendTo}
								reference={register({
									required: false,
									maxLength: 255,
									pattern: REGEX_EMAIL
								})}
								rules={{
									maxLength: 255
								}}
								errors={errors.sendTo}
							/>
						</div>
					</div>
				</div>

				<div className="form-controls form-controls--right">
					<button className="button button--primary" type="submit" disabled={isSubmitting}>
						<svg xmlns="http://www.w3.org/2000/svg" width="12" height="16">
							<path d="M7 4.25V0H.75A.748.748 0 000 .75v14.5a.748.748 0 00.75.75h10.5a.748.748 0 00.75-.75V5H7.75A.752.752 0 017 4.25zm2.389 6.605l-3.013 2.991a.533.533 0 01-.751 0l-3.013-2.991A.5.5 0 012.963 10H5V7.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V10h2.037a.5.5 0 01.352.855zm2.392-7.574L8.722.219A.749.749 0 008.191 0H8v4h4v-.191a.748.748 0 00-.219-.528z" />
						</svg>
						<span>{t('pages:reports:buttonSend')}</span>
					</button>
				</div>
			</form>
		</>
	)
}

export default ReportByOrganizationUsers
